import React from 'react';

const FacebookChatPlugin: React.FC = () => {
  React.useEffect(() => {
    const chatbox = document.getElementById('fb-customer-chat');
    chatbox?.setAttribute('page_id', '101640218618696');
    chatbox?.setAttribute('attribution', 'biz_inbox');

    // @ts-ignore
    window.fbAsyncInit = () => {
      // @ts-ignore
      FB.init({
        xfbml: true,
        version: 'v10.0',
      });
    };

    (() => {
      if (document.querySelector('#facebook-jssdk')) {
        return;
      }

      const head = document.querySelector('head');

      const script = document.createElement('script');
      script.setAttribute('async', '');
      script.setAttribute('id', 'facebook-jssdk');
      script.src = 'https://connect.facebook.net/id_ID/sdk/xfbml.customerchat.js';

      head?.appendChild(script);
    })();
  }, []);

  return (
    <div id="fb-root">
      <div id="fb-customer-chat" className="fb-customerchat" />
    </div>
  );
};

export default FacebookChatPlugin;
