import React from 'react';
// Modules
import { ChakraProvider } from '@chakra-ui/react';
import { AppProps } from 'next/app';
import Router, { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import NProgress from 'nprogress';
// Components
import FacebookChatPlugin from '../components/layout/FacebookChatPlugin';
// Contexts
import ContextProvider from '../contexts/ContextProvider';
// Firebase
import firebase from '../firebase/client';
// Styles
import '../styles/main.css';
// Utils
import * as logrocket from '../utils/logrocket';
import { init } from '../utils/sentry';
import theme from '../utils/theme';

interface Props extends AppProps {
  err?: Error;
}

init();

const CustomApp: React.FC<Props> = ({ Component, err, pageProps }) => {
  const router = useRouter();

  const canonical = React.useMemo(() => {
    if (router.asPath === '/') {
      return '';
    }

    return router.asPath;
  }, [router.asPath]);

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      firebase.analytics().setAnalyticsCollectionEnabled(false);
    }

    logrocket.init();

    const onRouteChangeStart = () => {
      NProgress.start();
    };

    const onRouteChangeComplete = () => {
      NProgress.done();
    };

    const onRouteChangeError = () => {
      NProgress.done();
    };

    Router.events.on('routeChangeStart', onRouteChangeStart);
    Router.events.on('routeChangeComplete', onRouteChangeComplete);
    Router.events.on('routeChangeError', onRouteChangeError);

    return () => {
      Router.events.off('routeChangeStart', onRouteChangeStart);
      Router.events.off('routeChangeComplete', onRouteChangeComplete);
      Router.events.off('routeChangeError', onRouteChangeError);
    };
  }, []);

  React.useEffect(() => {
    firebase.analytics().setCurrentScreen(router.asPath);
  }, [router.asPath]);

  return (
    <>
      <DefaultSeo
        additionalMetaTags={[
          { content: 'minimum-scale=1, initial-scale=1, width=device-width', name: 'viewport' },
        ]}
        canonical={`https://www.utamastationery.com${canonical}`}
        description="Alat tulis kantor dan sekolah. Melayani ecer dan grosir."
        openGraph={{
          description: 'Alat tulis kantor dan sekolah. Melayani ecer dan grosir.',
          images: [
            {
              alt: 'Utama Stationery',
              height: 630,
              url: 'https://www.utamastationery.com/images/og-image.jpg',
              width: 1200,
            },
          ],
          site_name: 'Utama Stationery',
          title: 'Utama Stationery',
          type: 'website',
          url: 'https://www.utamastationery.com',
        }}
        title="Utama Stationery"
      />
      <ChakraProvider theme={theme}>
        <ContextProvider>
          <Component {...pageProps} err={err} />
        </ContextProvider>
      </ChakraProvider>
      {process.env.NODE_ENV === 'production' && <FacebookChatPlugin />}
    </>
  );
};

export default CustomApp;
