import { extendTheme } from '@chakra-ui/react';
var theme = extendTheme({
  colors: {
    primary: {
      100: '#FFF8CF',
      200: '#FFEEA0',
      300: '#FFE271',
      400: '#FFD74E',
      500: '#FFC413',
      600: '#DBA20D',
      700: '#B78309',
      800: '#936506',
      900: '#7A5003'
    },
    secondary: {
      100: '#C5F6DE',
      200: '#8EEDC9',
      300: '#51CBA7',
      400: '#259780',
      500: '#005249',
      600: '#004645',
      700: '#00363B',
      800: '#00272F',
      900: '#001D27'
    }
  }
});
export default theme;