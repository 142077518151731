import { RewriteFrames } from '@sentry/integrations';
import * as Sentry from '@sentry/node';

export function init() {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const integrations = [];

    if (process.env.NEXT_IS_SERVER === 'true' && process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR) {
      integrations.push(
        new RewriteFrames({
          iteratee: (frame) => {
            frame.filename = frame.filename?.replace(
              process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR ?? '',
              'app:///',
            );
            frame.filename = frame.filename?.replace('.next', '_next');

            return frame;
          },
        }),
      );
    }

    Sentry.init({
      enabled: process.env.NODE_ENV === 'production',
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      integrations,
      release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    });
  }
}
